@import '../../../../jomalone2_base/scss/theme-bootstrap';

.lc-service-drawer-blip {
  #{$rdirection}: 0;
  background: $color-white;
  bottom: calc(47px + 4em);
  box-shadow: 0 0 4px $color-black;
  display: none;
  position: fixed;
  width: 100%;
  z-index: 99999;
  @include breakpoint($large-up) {
    #{$rdirection}: 5px;
    width: 400px;
  }
  h3.lc-service-drawer-blip__header-text {
    color: $color-white;
  }
  &__header-container {
    background: $color-classic-matte-black;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    [dir='rtl'] & {
      flex-direction: row-reverse;
    }
  }
  .device-mobile &__header-text,
  &__header-text {
    color: $color-white;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-transform: inherit;
  }
  &__button {
    background: $color-classic-matte-black;
    color: $color-white;
    cursor: pointer;
    display: block;
    font-size: 14px;
    margin: 10px 0;
    padding: 15px 10px;
    text-align: center;
    width: 100%;
  }
  &__body-container {
    font-weight: bold;
    padding: 10px 15px 15px;
    a:hover {
      color: $color-white;
    }
  }
  &__logo {
    fill: $color-light-grey;
    height: 2em;
    width: 8em;
  }
  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    @include breakpoint($medium-up) {
      height: 100%;
      width: 100%;
      z-index: 9990;
    }
  }
  &__text {
    color: $color-black;
  }
}

.rounded-live-chat-button {
  #{$rdirection}: 5px;
  align-items: center;
  background-color: $color-black;
  border: 2px solid $color-black;
  border-radius: 2em;
  bottom: 45px;
  color: $color-white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 1em;
  position: fixed;
  @include breakpoint($large-up) {
    z-index: 10000000;
  }
  &__down-caret {
    border-#{$rdirection}: 2px solid $color-white;
    border-bottom: 2px solid $color-white;
    height: 0.5em;
    margin: 0 10px 5px;
    transform: rotate(45deg);
    width: 0.5em;
  }
}

.sticky-footer-chat {
  display: none;
}

#blip-chat-open-iframe {
  display: none !important;
}